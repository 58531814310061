.FormListItem {
    background-color: #FFFFFF;
    min-width: 250px;
    margin-right: 10px;
    padding: 10px;
    border-radius: 7.5px;
    box-shadow: 0px 0px 7.5px #e0e0e0;
}

.FormListItem:last-child {
    margin-right: 3px;
}

.FormListItem .section1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 0.5px solid #EAECF0;
    height: 30px;
}

.FormListItem .section1 .formStatus {
    display: flex;
    align-items: center;
    padding: 2.5px 7.5px 2.5px 2.5px;
    border-radius: 15px;
}

.FormListItem .section1 .formStatus.new {
    background-color: rgb(255, 0, 0, 0.15);
    color: #E21D12;
}

.FormListItem .section1 .formStatus.inProgress,
.FormListItem .section1 .formStatus.changesSubmitted {
    background-color: rgb(255, 204, 0, 0.15);
    color: #B18F05;
}

.FormListItem .section1 .formStatus.complete {
    background-color: rgb(21, 132, 68, 0.15);
    color: #06612D;
}

.FormListItem .section1 .formStatus.complete svg path {
    fill: #158444;
}

.FormListItem .section1 .formStatus.submitted,
.FormListItem .section1 .formStatus.changesApproved {
    background-color: rgb(26, 94, 155, 0.2);
    color: #1A5E9B;
}

.FormListItem .section1 .formStatus.submitted svg path,
.FormListItem .section1 .formStatus.changesApproved svg path {
    fill: #1A5E9B;
}

.FormListItem .section1 .formStatus span.statusColor.new {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    display: inline-block;
    background-color: #ff0000;
    box-shadow: 0 0 5px #ff0000;
}


.FormListItem .section1 .formStatus span.statusText {
    font-size: 10px;
    font-weight: 400;
    white-space: nowrap;
    margin-left: 2.5px;
}

.FormListItem .section1 .creationDate {
    font-size: 11px;
    font-weight: 400;
    white-space: nowrap;
    color: rgb(25, 36, 52, 0.74);
}

.FormListItem .projectNumber {
    padding-top: 5px;
    font-size: 14px;
    font-weight: 700;
}

.FormListItem .titleOfInvention {
    padding-bottom: 10px;
    font-size: 12px;
    font-weight: 500;
    color: rgb(28, 48, 74, 0.65);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.FormListItem .formButtonContainer button {
    width: fit-content;
    border-radius: 7.5px;
    padding: 2.5px 10px;
    font-size: 13px;
    font-weight: 500;
}

.FormListItem .formButtonContainer button.new {
    background-color: #1A5E9B;
    color: #FFFFFF;
    border: 1px solid #1A5E9B;
}

.FormListItem .formButtonContainer button.new:hover {
    background-color: #FFFFFF;
    color: #1A5E9B;
    border: 1px solid #1A5E9B;
}

.FormListItem .formButtonContainer button.inProgress {
    background-color: #FFFFFF;
    color: #1A5E9B;
    border: 1px solid #1A5E9B;
}

.FormListItem .formButtonContainer button.inProgress:hover {
    background-color: #1A5E9B;
    color: #FFFFFF;
    border: 1px solid #1A5E9B;
}

.FormListItem .formButtonContainer button.submitted {
    background-color: rgb(26, 94, 155, 0.09);
    color: #1A5E9B;
    border: 1px solid rgb(26, 94, 155, 0.1);
}

.FormListItem .formButtonContainer button.submitted:hover {
    background-color: rgb(26, 94, 155, 0.09);
    color: #192434;
}