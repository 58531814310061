.App {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #F6F7F9;
  box-sizing: border-box;
}

.App .body {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* background-color: red; */
}

.App .body .navbar {
  height: 60px;
  padding: 0;
}

.App .body .pages {
  height: 100%;
  overflow: auto;
}