.NavBar {
    position: relative;
    background-color: #FFFFFF;
    /* background-color: pink; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 40px 0px 40px;
    border-bottom: 1.5px solid #EAECF0;
}

.NavBar svg {
    pointer-events: none;
    cursor: pointer;
}

.NavBar .logo>img {
    width: 45px;
}

.NavBar .dropdown {
    cursor: default;
}

.NavBar .profileDropDown {
    cursor: pointer;
}

.NavBar .profileDropDown .profileDropDownButton {
    padding: 10px;
    border-left: 1.5px solid#EAECF0;
}

.NavBar .profileDropDown .profileDropDownButton:hover {
    background-color: rgba(26, 94, 155, 0.05);
}

.NavBar .profileDropDown .dropdown-menu {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    width: 10vw;
    top: 40px;
    right: -20px;
}

.NavBar .profileDropDown .dropdown-menu div.userInfo {
    padding: 10px 20px;
}

.NavBar .profileDropDown .dropdown-item {
    border-top: 2px solid #F6F7F9;
}

.NavBar .profileDropDown .dropdown-item:hover {
    background-color: rgba(26, 94, 155, 0.05);
    color: #1A5E9B;
}