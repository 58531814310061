.FormList {
    height: 100%;
    width: 100%;
    overflow: auto;
    padding: 30px 100px;
}

.FormList .headerContent {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
}

.FormList .headerContent img {
    width: 100%;
    height: 100px;
    object-fit: cover;
}

.FormList .headerContent .mainHeaderContent {
    position: absolute;
    top: 10px;
    left: 20px;
    color: #FFFFFF;
}

.FormList .headerContent .mainHeaderContent .companyName {
    font-size: 30px;
    font-weight: 600;
}

.FormList .headerContent .mainHeaderContent .instructions {
    font-size: 14px;
}

.FormList .serviceItem {
    margin-bottom: 30px;
}

.FormList .serviceItem:last-child {
    margin-bottom: 0;
}

.FormList .serviceItem .serviceItemHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
    border-bottom: 2px solid #EAECF0;
}

.FormList .serviceItem .serviceItemTitle {
    font-size: 20px;
    font-weight: 600;
}

.FormList .serviceItem .buttonContainer {
    display: flex;
    align-items: center;
}

.FormList .serviceItem .buttonContainer button {
    padding: 0px;
    border-radius: 0px;
    background-color: rgb(255, 255, 255, 0);
}

.FormList .serviceItem .buttonContainer button svg {
    pointer-events: none;
}

.FormList .serviceItem .buttonContainer button:hover {
    background-color: rgb(138, 146, 166, 0.1);
}

.FormList .serviceItem .buttonContainer button:active {
    background-color: rgb(138, 146, 166, 0.2);
}

.FormList .serviceItem .serviceItemContainer {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    padding-top: 7.5px;
    padding-bottom: 5px;
}