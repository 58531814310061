.IntakeForm1.IntakeFormGlobal .upperSection .navigation button.nav-link {
    width: 25%;
}

.IntakeForm1.IntakeFormGlobal .demoContainer {
    display: flex;
    align-items: center;
}

.IntakeForm1.IntakeFormGlobal .demoContainer button.demoButton {
    background-color: rgb(95, 107, 122, 0.8);
    color: white;
    padding: 5px 15px;
    border-radius: 2.5px;
    margin-left: 20px;
}

.IntakeForm1.IntakeFormGlobal .demoContainer button.demoButton:hover {
    background-color: rgb(95, 107, 122, 1);
    border-radius: 5px;
}

.IntakeForm1.IntakeFormGlobal .demoContainer .modal .modal-dialog {
    min-width: 70%;
}

.IntakeForm1.IntakeFormGlobal .demoContainer .modal .modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 100%;
}

.IntakeForm1.IntakeFormGlobal .demoContainer img.demoImage {
    width: 100%;
}