.IntakeFormNotes.notesDropDown.show {
    width: 30vw;
    height: 40vh;
    border-radius: 10px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    background-color: #FFFFFF;
    border: 1px solid rgb(26, 56, 96, 0.2);
}

.IntakeFormNotes.notesDropDown .noteHeader {
    padding: 2.5px 10px;
    border-bottom: 1px solid rgb(26, 56, 96, 0.1);
    font-weight: 600;
    width: 100%;
}

.IntakeFormNotes.notesDropDown .noteContainer {
    padding: 10px;
    width: 100%;
    height: 100%;
}

.IntakeFormNotes.notesDropDown .noteContainer .noteBody {
    border: 1px solid rgb(28, 48, 74, 0.15);
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
}

.IntakeFormNotes.notesDropDown .noteContainer div.noteBody {
    width: 100%;
    background-color: rgb(25, 59, 103, 0);
    height: 100%;
    margin: 0px 0px 10px 0px;
    font-size: 14px;
    background-color: rgb(25, 59, 103, 0.04);
    white-space: break-spaces;
}